import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import clsx from "clsx";
import { Row, Col } from "antd";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";
import { selectLaunchDarklyInitialized } from "store/slices/launchDarkly";

import { WalletBalanceForm } from "./BalancePayoutComp";
import { Earnings } from "./Earnings";
import { LastPayoutWidget } from "./LastPayoutWidget";
import { PayoutsWidget } from "./PayoutsWidget";

import "./Home.scss";
import styles from "./Home.module.scss";

const DISABLE_SCHEDULING_TIMEOUT = 5 * 1000;

export const HomePageInternal = () => {
  const { selectedDriverProfile } = useSelectedFleet();
  const isLaunchDarklyInitialized = useSelector(selectLaunchDarklyInitialized);

  const {
    isPayoutsTableOnHomePageEnabled: payoutsTableEnabledFromSearchParams,
  } = useFeatureFlagsFromSearchParams();
  const { releasePayoutsListOnHomePage: payoutsTableEnabledFromLaunchDarkly } =
    useFlags();

  const payoutsTableEnabled =
    payoutsTableEnabledFromSearchParams || payoutsTableEnabledFromLaunchDarkly;

  const [schedulePayoutsRefresh, setSchedulePayoutsRefresh] = useState(false);

  useEffect(
    function disableSchedulingAfterTimeout() {
      if (!schedulePayoutsRefresh) return;

      const timeout = setTimeout(() => {
        setSchedulePayoutsRefresh(false);
      }, DISABLE_SCHEDULING_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    },
    [schedulePayoutsRefresh, setSchedulePayoutsRefresh]
  );

  return (
    <div className={clsx("home-wrapper", styles.homeWrapper)}>
      <div className={styles.balanceWrapper}>
        <Row gutter={[24, 24]} className="balance-payout-wrapper">
          <Col span={24}>
            <WalletBalanceForm
              driverProfile={selectedDriverProfile}
              setSchedulePayoutsRefresh={setSchedulePayoutsRefresh}
            />
          </Col>

          {isLaunchDarklyInitialized && payoutsTableEnabled ? (
            <PayoutsWidget schedulePayoutsRefetch={schedulePayoutsRefresh} />
          ) : null}

          {isLaunchDarklyInitialized && !payoutsTableEnabled ? (
            <LastPayoutWidget driverProfile={selectedDriverProfile} />
          ) : null}
        </Row>
      </div>
      <div className={styles.earningsWrapper}>
        <Earnings driverProfileId={selectedDriverProfile?.id} />
      </div>
    </div>
  );
};
