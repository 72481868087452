import React from 'react';
import { useCallback } from 'react';

export const ScrollTop = ({
  scrollId,
  className,
  customLabel
}) => {
  const scrollToId = useCallback(() => {
    const elementToScrollTo = document.getElementById(scrollId);
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollId]);

  return (
    <div className={`scrollTop ${className || ''}`} onClick={scrollToId}>{
      customLabel ? customLabel : 'Scroll to top'
    }</div>
  )
}
