import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";
import "antd/dist/antd.min.css";

import { store } from "./store/store";
import { CurrentCompanyIdContextProvider } from "./CurrentCompanyIdContext";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
  });
}

(async function () {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <LDProvider>
      <StrictMode>
        <Suspense>
          <Provider store={store}>
            <CurrentCompanyIdContextProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CurrentCompanyIdContextProvider>
          </Provider>
        </Suspense>
      </StrictMode>
    </LDProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
