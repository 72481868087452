import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { api } from "api";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";

export const useLogout = () => {
  const { t } = useTranslation();
  const [logOut] = api.endpoints.logout.useMutation();
  const navigate = useNavigate();

  return useCallback(() => {
    message.loading({
      content: t("processing.signingOut"),
      duration: 0,
      key: "log-out",
    });

    const token = JSON.parse(localStorage.getItem("auth_token"));
    if (token) {
      logOut()
        .unwrap()
        .then(() => {
          message.success({
            content: t("success.signedOut"),
            duration: 3,
            key: "log-out",
          });
        })
        .catch(() => {
          message.error({
            content: t("error.somethingWentWrong"),
            duration: 3,
            key: "log-out",
          });
        })
        .finally(() => {
          localStorage.clear();
          window.location.pathname = "";
        });
    } else {
      navigate(routes.signIn.getURL());
      message.error({
        content: t("error.youNotAuthenticated"),
        duration: 10,
        key: "log-out",
      });
    }
  }, [logOut, navigate, t]);
};
