import { log } from "helpers/logger";
import { DocumentType } from "./DocumentType";

const logInternal = (...args) => log("getReadonlyDocument.js", ...args);

const getterByDocumentType = {
  [DocumentType.ADDRESS_PROOF]: (document, { currentDriverProfile }) => ({
    extra_info: {
      address: currentDriverProfile?.address,
    },
    files: [],
  }),
  [DocumentType.DRIVER_LICENSE]: (document, { currentDriverProfile }) => ({
    extra_info: {
      licence: currentDriverProfile?.driver_licence,
    },
    expire_at: currentDriverProfile?.driver_licence_expiry,
    files: [],
  }),
  [DocumentType.BADGE]: (document, { currentDriverProfile }) => ({
    extra_info: {
      badge_number: currentDriverProfile?.badge,
      badge_type: currentDriverProfile?.badge_type,
    },
    expire_at: currentDriverProfile?.badge_expiry,
    files: [],
  }),

  [DocumentType.VEHICLE_DETAILS]: (document, { currentVehicle }) => ({
    extra_info: {
      reg: currentVehicle?.reg,
      make: currentVehicle?.make,
      model: currentVehicle?.model,
      colour: currentVehicle?.colour,
    },
  }),
  [DocumentType.PLATE]: (document, { currentVehicle }) => ({
    extra_info: {
      plate: currentVehicle?.plate,
    },
    expire_at: currentVehicle?.plate_expiry,
    files: [],
  }),
  [DocumentType.INSURANCE]: (document, { currentVehicle }) => ({
    extra_info: {
      insurance: currentVehicle?.insurance,
      insurer: currentVehicle?.insurer,
    },
    expire_at: currentVehicle?.insurance_expiry,
    files: [],
  }),
};

const defaultGetter = (document) => ({
  extra_info: document?.extra_info ?? {},
  files: [],
  expire_at: document?.expire_at,
});

const customDocumentGetter = (document, { documents }) => {
  logInternal("customDocumentGetter", "started", {
    document,
    documents,
  });

  const allApprovedDocumentsOfThisType = documents
    .filter(
      ({ custom_document_type_id }) =>
        custom_document_type_id === document.custom_document_type_id
    )
    .filter(({ status }) => status === "approved");

  logInternal(
    "customDocumentGetter",
    "allApprovedDocumentsOfThisType",
    allApprovedDocumentsOfThisType
  );

  const lastApprovedDocumentOfThisType =
    allApprovedDocumentsOfThisType[allApprovedDocumentsOfThisType.length - 1];

  logInternal(
    "customDocumentGetter",
    "lastApprovedDocumentOfThisType",
    lastApprovedDocumentOfThisType
  );

  return {
    extra_info: lastApprovedDocumentOfThisType?.extra_info ?? {},
    files: [],
    expire_at: lastApprovedDocumentOfThisType?.expire_at,
  };
};

export function getReadonlyDocument({ document, documentSchema, context }) {
  logInternal("getReadonlyDocument", "started", {
    document,
    documentSchema,
    context,
  });

  const correspondingGetter =
    document?.type === DocumentType.CUSTOM_DOCUMENT
      ? customDocumentGetter
      : getterByDocumentType[documentSchema.id];

  if (typeof correspondingGetter !== "function") {
    logInternal(
      "getReadonlyDocument",
      `The getter for type "${documentSchema?.id}" is not registered. 'defaultGetter' is used instead`
    );
    return defaultGetter(document, context);
  }

  return correspondingGetter(document, context);
}
