import { ContactSection } from "./ContactSection";
import { PasswordSection } from "./PasswordSection";
import { StripeStatusSection } from "./StripeStatusSection";
import { BankDetailsSection } from "./BankDetailsSection";

import styles from "./Profile.module.scss";

export const Profile = ({ fleet, profile }) => {
  const isStripeActive =
    profile.stripe_details_submitted && profile.stripe_payouts_enabled;

  return (
    <div className={styles.innerContainer}>
      <ContactSection profile={profile} />
      <PasswordSection />

      {fleet.enable_driver_pay ? (
        <StripeStatusSection profile={profile} />
      ) : null}

      {fleet.enable_driver_pay && isStripeActive ? (
        <BankDetailsSection profile={profile} />
      ) : null}
    </div>
  );
};
