import { api } from "api";
import { isFeatureEnabled } from "featureFlags";

import {
  usePredefinedDriverDocumentTypes,
  usePredefinedVehicleDocumentTypes,
} from "./constants";
import { useQueryPredefinedDocumentTypes } from "./DocumentTypeDetails";

export function useQueryDocumentTypes() {
  const predefinedDocumentTypes = useQueryPredefinedDocumentTypes();
  return {
    data: Object.values(predefinedDocumentTypes),
    isLoading: false,
  };
}

const noDocuments = [];

function useCommonDetails(callApi, ...args) {
  const predefinedDriverDocumentTypes = usePredefinedDriverDocumentTypes();
  const predefinedVehicleDocumentTypes = usePredefinedVehicleDocumentTypes();
  const { data: documentTypes, isLoading: isMockedDocumentTypesLoading } =
    useQueryDocumentTypes();

  const { data, isLoading: areCustomDocumentTypesLoading } = callApi(...args);
  const customDocumentTypes = data?.data ?? noDocuments;

  const customDriverDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("driver_profile")
  );

  const customApplicationDriverDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("driver_profile") &&
      type.documentable_types.includes("driver_application")
  );

  const customVehicleDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("vehicle")
  );

  const customApplicationVehicleDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("vehicle") &&
      type.documentable_types.includes("driver_application")
  );

  const customApplicationOnlyDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.length === 1 &&
      type.documentable_types.includes("driver_application")
  );

  const onboardingSteps = [
    ...predefinedDriverDocumentTypes,
    ...customApplicationDriverDocumentTypes,
    ...customApplicationOnlyDocumentTypes,
    ...predefinedVehicleDocumentTypes,
    ...customApplicationVehicleDocumentTypes,
  ]
    .map((type) => String(type.id))
    .concat("submit");

  return {
    isLoading: isMockedDocumentTypesLoading || areCustomDocumentTypesLoading,
    documentTypes: [...documentTypes, ...customDocumentTypes],
    predefinedDriverDocumentTypes,
    customDriverDocumentTypes,
    customApplicationDriverDocumentTypes,
    customApplicationOnlyDocumentTypes,
    predefinedVehicleDocumentTypes,
    customVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
    onboardingSteps,
  };
}

export function useQueryCustomDocumentTypesDetail(
  { driverId, currentCompanyId },
  flag = false
) {
  const request = flag
    ? api.endpoints.getDriverCustomDocumentTypes.useQuery
    : api.endpoints.getCustomDocumentTypes.useQuery;

  const payload = flag
    ? [driverId]
    : [
        {
          fleet_id: currentCompanyId,
        },
        {
          skip:
            !isFeatureEnabled.CUSTOM_DOCUMENTS ||
            currentCompanyId === undefined ||
            currentCompanyId === null,
        },
      ];
  return useCommonDetails(request, ...payload);
}
