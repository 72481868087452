const logInternal =
  process.env.NODE_ENV === "production"
    ? () => undefined
    : (text, payload) => {
        console.warn(`%c${text}`, "color: chocolate");

        if (payload !== undefined) {
          console.warn(payload);
        }
      };

const filter = {
  // type: "only-file",
  // value: "OnboardingBreadcrumbs.jsx",
};

function logWrapper(file, block, comment, payload) {
  logInternal(`${file}:${block}:${comment}`, payload);
}

export function log(file, block, comment, payload) {
  if (filter.type === "only-file" && file !== filter.value) return;

  logWrapper(file, block, comment, payload);
}
