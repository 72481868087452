import { useParams } from "react-router-dom";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { LoadingComp } from "components/LoadingComp";

import { DriverDocumentForm } from "./components/DriverDocumentForm";
import { DriverDocumentLayout } from "./DriverDocumentLayout";
import { useQueryDocumentTypeById } from "./useQueryDocumentTypeById";

export function DriverDocumentPage({ isEditPage = false } = {}) {
  const { documentTypeId } = useParams();

  const { selectedFleetId } = useSelectedFleet();
  const { data: currentDocumentTypeDetails, isLoading } =
    useQueryDocumentTypeById({
      documentTypeId,
      currentCompanyId: selectedFleetId,
    });

  if (!currentDocumentTypeDetails || isLoading) {
    return (
      <DriverDocumentLayout>
        <LoadingComp loading fullScreen />
      </DriverDocumentLayout>
    );
  }

  return (
    <DriverDocumentLayout>
      <DriverDocumentForm
        isEditPage={isEditPage}
        documentTypeId={currentDocumentTypeDetails.id}
        documentTypeDetails={currentDocumentTypeDetails}
      />
    </DriverDocumentLayout>
  );
}
