/**
 * Routes is an object, properties of which relate to the specific route.
 * (todo) It should be the only place in the codebase, where URL-related data
 * is stored.
 *
 * Specific route is an object that has two properties: `path` and `getURL`.
 *
 * `path` is for <Route>'s path property
 * (https://reactrouter.com/en/main/route/route#path).
 * Example `path`: "transactions/:bookingId".
 *
 * `getURL` is a custom function to generate needed url, which correlates to
 * some `path`.
 * Example `getURL`: ({ bookingId }) =>
 * `/transactions/${bookingId}`
 */
export const routes = Object.freeze({
  index: {
    path: "/",
    getURL: (fleetSlug) => {
      if (fleetSlug === undefined || fleetSlug === null) {
        return "/";
      }

      const params = new URLSearchParams({
        "join-fleet": fleetSlug,
      });
      return `/?${params.toString()}`;
    },
  },
  signIn: {
    path: "/sign-in",
    getURL: (fleetSlug) => {
      if (fleetSlug === undefined) {
        return "/sign-in";
      }

      const params = new URLSearchParams({
        "join-fleet": fleetSlug,
      });

      return `/sign-in?${params.toString()}`;
    },
  },
  forgetPassword: {
    path: "/forget-password/email",
    getURL(email) {
      if (typeof email !== "string" || email.length === 0) {
        return "/forget-password/email";
      }

      const params = new URLSearchParams({
        email,
      });

      return `/forget-password/email?${params.toString()}`;
    },
  },
  createNewPassword: {
    path: "/forget-password",
    getURL: () => "/forget-password",
  },
  resetPasswordEmailSent: {
    path: "/forget-password/email-sent",
    getURL(email) {
      if (email === undefined) {
        return "/forget-password/email-sent";
      }

      const params = new URLSearchParams({
        email,
      });

      return `/forget-password/email-sent?${params.toString()}`;
    },
  },
  joinFleet: {
    path: "/join-fleet/:fleetSlug",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}`,
  },
  verifyEmail: {
    path: "/email/verify",
    getURL: () => "/email/verify",
  },

  driverDocument: {
    path: "/wallet/profile/documents/:documentTypeId",
    getURL: ({ documentTypeId }) =>
      `/wallet/profile/documents/${documentTypeId}`,
  },
  driverDocumentEdit: {
    path: "/wallet/profile/documents/:documentTypeId/edit",
    getURL: ({ documentTypeId }) =>
      `/wallet/profile/documents/${documentTypeId}/edit`,
  },

  vehicleDocument: {
    path: "/wallet/profile/documents/:documentTypeId/vehicle/:vehicleId",
    getURL: ({ vehicleId, documentTypeId }) =>
      `/wallet/profile/documents/${documentTypeId}/vehicle/${vehicleId}`,
  },
  vehicleDocumentEdit: {
    path: "/wallet/profile/documents/:documentTypeId/vehicle/:vehicleId/edit",
    getURL: ({ vehicleId, documentTypeId }) =>
      `/wallet/profile/documents/${documentTypeId}/vehicle/${vehicleId}/edit`,
  },

  onboardingSignUpPage: {
    path: "/join-fleet/:fleetSlug/sign-up",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}/sign-up`,
  },
  onboardingCreateDriverProfilePage: {
    path: "/join-fleet/:fleetSlug/driver-details",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}/driver-details`,
  },

  onboardingDocument: {
    path: "/join-fleet/:fleetSlug/:documentTypeId",
    getURL: ({ fleetSlug, documentTypeId }) =>
      `/join-fleet/${fleetSlug}/${documentTypeId}`,
  },

  onboardingApplicationIsSubmitted: {
    path: "/join-fleet/:fleetSlug/application-submitted",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}/application-submitted`,
  },

  onboardingSelectPayoutAccount: {
    path: "/join-fleet/:fleetSlug/payouts-account/:driverProfileId",
    getURL: (fleetSlug, driverProfileId) =>
      `/join-fleet/${fleetSlug}/payouts-account/${driverProfileId}`,
  },

  onboardingStripeKyc: {
    path: "/join-fleet/:fleetSlug/stripe-kyc",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}/stripe-kyc`,
  },

  checkEmail: {
    path: "/join-fleet/:fleetSlug/check-email",
    getURL: (fleetSlug) => `/join-fleet/${fleetSlug}/check-email`,
  },

  documents: {
    path: "/wallet/profile/documents",
    getURL: () => "/wallet/profile/documents",
  },

  walletHome: {
    path: "/wallet",
    getURL: () => "/wallet",
  },

  profilePage: {
    path: "/wallet/profile",
    getURL: () => "/wallet/profile",
  },

  walletBookings: {
    path: "/wallet/wallet_bookings",
    getURL: () => "/wallet/wallet_bookings",
  },

  walletBookingsItem: {
    path: "/wallet/wallet_bookings/:bookingId",
    getURL: (bookingId) => `/wallet/wallet_bookings/${bookingId}`,
  },

  notificationsPage: {
    path: "/wallet/notifications",
    getURL: () => "/wallet/notifications",
  },

  faqsPage: {
    path: "/wallet/faqs",
    getURL: () => "/wallet/faqs",
  },

  consoleDashboard: {
    path: "/console/dashboard",
    getURL: () => "/console/dashboard",
  },

  stripeCompleted: {
    path: "/stripe_completed",
    getURL: () => "/stripe_completed",
  },

  welcome: {
    path: "/welcome",
    getURL: () => "/welcome",
  },

  authWithToken: {
    path: "/auth/token",
    getURL: (token) => {
      if (typeof token !== "string") {
        return "/auth/token";
      }

      const params = new URLSearchParams({
        token,
      });
      return `/auth/token?${params.toString()}`;
    },
  },

  termsAndConditions: {
    path: "/terms-and-conditions",
    getURL: () => "/terms-and-conditions",
  },

  completeRegistration: {
    path: "/user/new/:invitation_code",
    getURL: (invitationCode) => `/user/new/${invitationCode}`,
  },

  signUp: {
    path: "/signup",
    getURL: () => "/signup",
  },
});
