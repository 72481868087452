import { Button } from "antd";
import { cloneElement } from "react";
import "./CustomButton.scss";

export const CustomButton = ({
  children,
  disabled = false,
  Icon,
  onClick = () => {},
  className = "",
}) => (
  <Button
    onClick={onClick}
    className={`custom-btn ${className}`.trim()}
    disabled={disabled}
  >
    <div className="btn-content">{children}</div>
    {Icon && cloneElement(Icon, { className: "btn-icon" })}
  </Button>
);
